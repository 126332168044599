<style scoped>
        html,
        body {
            height: 100%;
            background-color: #F3F3F3;
            padding:0;
            margin:0;
        }
        .login { 
            width:100%;
            background-color: #310f34;
            
        }
        .loginCon {
            background: #fff;
            width:30%;
            height:40%;
            margin:0 auto;
        }
            .loginCon div { 
                height:20%;
                padding-top:5%;
            }
            .header{background: #714b72;color: white;}
            .loginCon img { 
                width:10%;vertical-align: middle;
            }
            .loginCon input { 
                border:0;
                width:85%;
                height:100%; font-size: 1rem;
                border: 1px solid #ddd;
            }
        .btnLogin { 
            width:50% !important;
            padding-top:0px !important;
            margin-top:1%;
            border-radius:5px;
            color: #fff;
            font-size: 24px;
            font-weight: bolder;
        }
        @media (max-width:575px) {
            /* .loginCon {
                width:60%;
            } */
        }
        @media (min-width:576px) and (max-width:767px) { 
            .loginCon {
                width:40%;
            }
            .loginCon img { 
                width:15%;vertical-align: middle;
            }
            .loginCon input { 
                border:0;
                width:80%;
                height:100%; font-size: 1rem;
            }
        }
    </style>
<template>
    <!-- <div class="login d-flex flex-column justify-content-center">
        <div class="loginCon">
            <div class="header">Login Form</div>
            <div>
                <input name="UserNameStr" type="text" placeholder="用户名" id="u" />
            </div>
            <div>
                <input name="PassWordStr" type="text" placeholder="密码" id="p" />
            </div>
            <div>
                <button type="button" class="btn btn-downitem btnLogin ms-3 me-3" name="LoginCmd" id="login_button" >登 录</button>
            </div>
        </div>
    </div> -->
    <div class="bg-purple w-100 h-100">
        <div class="d-flex flex-column justify-content-center col-8 col-sm-6 col-md-5 col-lg-4 col-xl-3 text-start mx-auto h-100">
            <div class="card">
                <div class="card-header bg-info bg-gradient fw-bold">登录</div>
                <div class="card-body">
                    <div class="d-flex justify-content-between mb-3">
                        <div class="d-flex flex-column justify-content-around me-3 text-nowrap">
                            <div class="d-flex flex-column justify-content-center col mb-3">
                                <label for="u">用户名:</label>
                            </div>
                            <div class="d-flex flex-column justify-content-center col">
                                <label for="p">密码:</label>
                            </div>
                        </div>
                        <div class="flex-grow-1">
                            <input type="text" class="form-control mb-3" id="u" placeholder="用户名" name="username">
                            <input type="password" class="form-control" id="p" placeholder="密码" name="pwd">
                        </div>
                    </div>
                    <div class="text-end">
                        <button class="btn btn-outline-info" id="login_button">登录</button>
                    </div>
                </div> 
            </div>
        </div>
    </div>
</template>
<script>
    export default{
        mounted(){
            let _this=this;
            $(function () {
        //点击登录
        $('#u').attr('name', 'u' + new Date().getTime());
        $('#p').attr('name', 'p' + new Date().getTime());
        $('#p').focus(function (e) {
            $(e.target).attr('type', 'password');
        })
        $('#login_button').click(()=> {
            var u = $('#u').val();
            var p = $('#p').val();
            if (u == '' || u == null) {
                alert('请输入账号');
                return;
            } else if (p == '' || p == null) {
                alert('请输入密码');
                return;
            }
            else if (u == '888888' && p == '666666') {
                sessionStorage.setItem('userInfo', u);
                _this.$router.push('/home');
                // window.location.href = '/home';
            }
            else {
                alert('账号或密码输入错误');
            }
        })
        //回车
        $(window).keydown(function (e) {
            e.keyCode == 13 && $('#login_button').click();
        })

    })
        }
    }
</script>